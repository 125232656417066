import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/var/www/comidocnext4/source/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/comidocnext4/source/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/www/comidocnext4/source/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/comidocnext4/source/src/layout/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutView"] */ "/var/www/comidocnext4/source/src/layout/LayoutView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/var/www/comidocnext4/source/src/providers/ReactQueryProvider.tsx");
